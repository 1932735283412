import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Drawer, Menu, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../redux/authSlice";

const AppHeader = () => {
  const [drawerVisible, setDrawerVisible] = useState(false);

  const toggleDrawer = () => {
    setDrawerVisible(!drawerVisible);
  };

  const isAuthenticate = useSelector((state) => state.auth.isAuthenticate);
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
  };

  const navigate = useNavigate();

  const CloseSidebar = () => {
    setDrawerVisible(false)
  };

  useEffect(() => {
    CloseSidebar();
  }, [navigate]);

  return (
    <div className="container">
      <Row justify="space-between" align="middle">
        <Col>
          <div className="logo">
            <Link to={"/"}>
              <img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="" />
            </Link>
          </div>
        </Col>
        <Col flex="auto" className="desktop-menu navbar-menu">
          <Menu
            mode="horizontal"
            defaultSelectedKeys={["1"]}
            overflowedIndicator={null}
          >
            <Menu.SubMenu key={"1"} title="Home">
              <Menu.Item key="11" className="submenu-parent">
                <Link to={"/singleHotel/search"} className="submenu-link">
                  Single Hotel
                </Link>
              </Menu.Item>
              <Menu.Item key="12" className="submenu-parent">
                <Link to={"/"} className="submenu-link">
                  Multiple Hotels
                </Link>
              </Menu.Item>
            </Menu.SubMenu>

            <Menu.SubMenu key={"3"} title="Experiences">
              <Menu.Item key="31" className="submenu-parent">
                <Link to={"/personalization"} className="submenu-link">
                  With Tab
                </Link>
              </Menu.Item>
              <Menu.Item key="32" className="submenu-parent">
                <Link
                  to={"/withoutTab/personalization"}
                  className="submenu-link"
                >
                  Without Tab
                </Link>
              </Menu.Item>
            </Menu.SubMenu>

            <Menu.Item key="4">About</Menu.Item>

            <Menu.Item key="5">Services</Menu.Item>

            <Menu.Item key="6">Offers</Menu.Item>

            {isAuthenticate ? (
              <Menu.SubMenu key={"7"} title="Profile">
                <Menu.Item key="71" className="submenu-parent">
                  <Link onClick={handleLogout} className="submenu-link">
                    Logout
                  </Link>
                </Menu.Item>
              </Menu.SubMenu>
            ) : (
              <Menu.SubMenu key={"8"} title="Signin or Join">
                <Menu.Item key="81" className="submenu-parent">
                  <Link to={"/login"} className="submenu-link">
                    Sign in
                  </Link>
                </Menu.Item>
                <Menu.Item key="82" className="submenu-parent">
                  <Link to={"/register"} className="submenu-link">
                    Sign Up
                  </Link>
                </Menu.Item>
              </Menu.SubMenu>
            )}
          </Menu>
        </Col>

        <Col className="mobile-menu">
          <Button
            type="text"
            icon={<FontAwesomeIcon icon={faBars} />}
            onClick={toggleDrawer}
          />
        </Col>
      </Row>

      <Drawer
        title="Menu"
        placement="right"
        onClose={toggleDrawer}
        visible={drawerVisible}
        className="mobile-sidebar-menu"
      >
        <Menu mode="inline" defaultSelectedKeys={["1"]}>
          <Menu.SubMenu key={"1"} title="Home">
            <Menu.Item key="11" className="submenu-parent-mobile">
              <Link to={"/singleHotel/search"} className="submenu-link-mobile">
                Single Hotel
              </Link>
            </Menu.Item>
            <Menu.Item key="12" className="submenu-parent-mobile">
              <Link to={"/"} className="submenu-link-mobile">
                Multiple Hotels
              </Link>
            </Menu.Item>
          </Menu.SubMenu>

          <Menu.SubMenu key={"3"} title="Experiences">
            <Menu.Item key="31" className="submenu-parent-mobile">
              <Link to={"/personalization"} className="submenu-link-mobile">
                With Tab
              </Link>
            </Menu.Item>
            <Menu.Item key="32" className="submenu-parent-mobile">
              <Link
                to={"/withoutTab/personalization"}
                className="submenu-link-mobile"
              >
                Without Tab
              </Link>
            </Menu.Item>
          </Menu.SubMenu>

          <Menu.Item key="4">About</Menu.Item>

          <Menu.Item key="5">Services</Menu.Item>

          <Menu.Item key="6">Offers</Menu.Item>

          {isAuthenticate ? (
            <Menu.SubMenu key={"7"} title="Profile">
              <Menu.Item key="71" className="submenu-parent-mobile">
                <Link onClick={handleLogout} className="submenu-link-mobile">
                  Logout
                </Link>
              </Menu.Item>
            </Menu.SubMenu>
          ) : (
            <Menu.SubMenu key={"8"} title="Signin or Join">
              <Menu.Item key="81" className="submenu-parent-mobile">
                <Link to={"/login"} className="submenu-link-mobile">
                  Sign in
                </Link>
              </Menu.Item>
              <Menu.Item key="82" className="submenu-parent-mobile">
                <Link to={"/register"} className="submenu-link-mobile">
                  Sign Up
                </Link>
              </Menu.Item>
            </Menu.SubMenu>
          )}
        </Menu>
      </Drawer>
    </div>
  );
};

export default AppHeader;
