import React, { useEffect, useState } from "react";
import {
  Input,
  Button,
  Row,
  Col,
  Checkbox,
  message,
  Form,
  InputNumber,
  Radio,
  Modal,
} from "antd";
import Cart2 from "./Cart2";
import { BsEmojiHeartEyesFill } from "react-icons/bs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLeftLong } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import { useSelector } from "react-redux";
import Loader from "./Loader";

const hotelsData = [
  {
    id: "hotel1",
    img: "images/hotel-img-01.jpg",
    hotelname: "Palladium Hotel",
  },
  {
    id: "hotel2",
    img: "images/hotel-img-02.jpg",
    hotelname: "HRH Nevada",
  },
  {
    id: "hotel3",
    img: "images/hotel-img-03.jpg",
    hotelname: "Blocher Hotels & Resorts",
  },
];

const ConfirmForm = () => {
  const API_BASE_URL = "https://www.hotelapps.cloud/api";
  // const API_BASE_URL = "http://localhost/hotel-management";

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const getCartData = useSelector((state) => state.cart);

  const formatDate = (date) => {
    if (!date) return "";
    const [day, month, year] = date.split("-");
    return `${year}-${month}-${day}`;
  };
  const checkIn = formatDate(getCartData?.date?.checkIn);
  const checkOut = formatDate(getCartData?.date?.checkOut);
  const night = getCartData?.date.nights;
  const adult = getCartData?.roomAndGuest.adults;
  const children = getCartData?.roomAndGuest.children;

  const hotelID = getCartData?.selectedRoom.hotelId;

  const selectedHotel = hotelsData.find((hotel) => hotel.id === hotelID);

  const hotelName = selectedHotel?.hotelname;

  const roomId = getCartData?.selectedRoom.id;
  const roomType = getCartData?.selectedRoom.type;
  const roomName = getCartData?.selectedRoom.name;
  const roomPrice = getCartData?.selectedRoom.price;
  const amenities =
    getCartData?.items?.map(({ id, name, date, time, price }) => ({
      id,
      name,
      date,
      time,
      price,
    })) || [];
  const subTotal = getCartData?.subTotal;
  const taxes = getCartData?.taxes;
  const discount = getCartData?.discount;
  const promocode = getCartData?.appliedPromoCode;
  const promodiscount = getCartData?.promoAmount;
  const totalDue = getCartData?.totalAmount;

  const [formData, setFormData] = useState({
    fName: "",
    lName: "",
    email: "",
    mobileNumber: "",

    address1: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    zipCode: "",

    preferences: [],
    other_request: "",
    extraFries: false,

    creditCardName: "",
    creditCardNumber: "", // Securely handle this
    expirationMonth: "",
    expirationYear: "",
    CVVNumber: "",
    BillingZipCode: "",

    acceptTermCondition: false,

    checkIn: null, // Better as null instead of empty string
    checkOut: null,
    night: 1,
    adult: 1,
    children: 0,

    hotelID: "",
    hotelName: "",

    roomId: "",
    roomType: "Offer",
    roomName: "",
    roomPrice: 0, // Default should be a number

    amenities: [{ id: "", name: "", date: null, time: null, price: 0 }],

    subTotal: 0,
    taxes: 0,
    discount: 0,
    promocode: "",
    promodiscount: 0,
    totalDue: 0,

    bookingStatus: "pending",
    paymentStatus: "pending",
  });

  const handleCustomFieldsChange = (fieldName, newValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: newValue,
    }));
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  useEffect(() => {
    if (getCartData) {
      setFormData((prev) => ({
        ...prev,
        checkIn,
        checkOut,
        night,
        adult,
        children,
        hotelID,
        hotelName,
        roomId,
        roomType,
        roomName,
        roomPrice,
        amenities,
        subTotal,
        taxes,
        discount,
        promocode,
        promodiscount,
        totalDue,
      }));
    }
  }, [getCartData]);

  const handleSubmit = async () => {
    setLoading(true);
    console.log(formData);
    try {
      const response = await fetch(`${API_BASE_URL}/submitBooking.php`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });
      const result = await response.json();
      if (response.ok) {
        // message.success("Booking successful!");
        setLoading(false);
        setIsModalOpen(true);
      } else {
        setLoading(false);
        message.error("Error in booking: " + result.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error submitting form:", error);
      message.error("An error occurred while submitting the form.");
    }
  };

  const handlePreferencesChange = (preference) => {
    setFormData((prevData) => {
      const newPreferences = prevData.preferences.includes(preference)
        ? prevData.preferences.filter((pref) => pref !== preference)
        : [...prevData.preferences, preference];
      return { ...prevData, preferences: newPreferences };
    });
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleBackToHome = () => {
    setIsModalOpen(false);
    window.location.href = "/";
  };

  return (
    <div className="confirmation-form">
      <Modal
        title="Booking Successful"
        open={isModalOpen}
        footer={[
          <Button key="home" type="primary" onClick={handleBackToHome}>
            Back to Home
          </Button>,
        ]}
        closable={false}
        centered
      >
        <p>Your booking has been successfully completed!</p>
      </Modal>

      {loading && <Loader />}

      <div className="back_btn">
        <Link to={"/personalization"}>
          <button>
            <FontAwesomeIcon icon={faLeftLong} />
          </button>
          Back
        </Link>
      </div>
      <Row
        gutter={24}
        className="confirmation-row"
        style={{ flexWrap: "wrap-reverse", gap: "30px 0" }}
      >
        <Col xs={24} sm={24} md={24} lg={15} xl={15}>
          <div className="guest-information">
            {/* <h3>Guest Information</h3> */}
            <Row gutter={24}>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <div className="form-group">
                  <label className="form-label">First Name</label>
                  <Input
                    name="fName"
                    value={formData.fName}
                    onChange={handleChange}
                    placeholder="First Name"
                  />
                </div>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <div className="form-group">
                  <label className="form-label">Last Name</label>
                  <Input
                    name="lName"
                    value={formData.lName}
                    onChange={handleChange}
                    placeholder="Last Name"
                  />
                </div>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <div className="form-group">
                  <label className="form-label">Email</label>
                  <Input
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Email"
                  />
                </div>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <div className="form-group">
                  <label className="form-label">Member Number (Optional)</label>
                  <Input
                    name="mobileNumber"
                    value={formData.mobileNumber}
                    onChange={handleChange}
                    placeholder="Mobile Number"
                  />
                </div>
              </Col>
            </Row>
          </div>
          <div className="guest-address">
            {/* <h3>Guest Address</h3> */}
            <Row gutter={24}>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <div className="form-group">
                  <label className="form-label">Address Line 1</label>
                  <Input
                    name="address1"
                    value={formData.address1}
                    onChange={handleChange}
                    placeholder="Address"
                  />
                </div>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <div className="form-group">
                  <label className="form-label">Address Line 2</label>
                  <Input
                    name="address2"
                    value={formData.address2}
                    onChange={handleChange}
                    placeholder="Address"
                  />
                </div>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <div className="form-group">
                  <label className="form-label">City</label>
                  <Input
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    placeholder="city"
                  />
                </div>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <div className="form-group">
                  <label className="form-label">State/Provine</label>
                  <Input
                    name="state"
                    value={formData.state}
                    onChange={handleChange}
                    placeholder="state"
                  />
                </div>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <div className="form-group">
                  <label className="form-label">Country/Region</label>
                  <Input
                    name="country"
                    value={formData.country}
                    onChange={handleChange}
                    placeholder="country"
                  />
                </div>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <div className="form-group">
                  <label className="form-label">Zip Code / Postal Code</label>
                  <Input
                    name="zipCode"
                    value={formData.zipCode}
                    onChange={handleChange}
                    placeholder="zipCode"
                  />
                </div>
              </Col>
            </Row>
          </div>
          <div className="room-preference">
            {/* <h3>Room Request and Preferences</h3> */}
            <div className="room-preference-option">
              <h4>Room request and preferences</h4>
              <div className="room-preference-option-list">
                <Checkbox
                  onChange={() => handlePreferencesChange("Early Check in")}
                >
                  Early Check-in
                </Checkbox>
                <Checkbox
                  onChange={() => handlePreferencesChange("Late Check out")}
                >
                  Late Check out
                </Checkbox>
                <Checkbox
                  onChange={() => handlePreferencesChange("In-Room services")}
                >
                  In-Room services
                </Checkbox>
                <Checkbox
                  onChange={() =>
                    handlePreferencesChange("Extra towel, pillow, blanket")
                  }
                >
                  Extra towel, pillow, blanket
                </Checkbox>
                <Checkbox
                  onChange={() =>
                    handlePreferencesChange("Baby Crib or Antilop High Chair")
                  }
                >
                  Baby Crib or Antilop High Chair
                </Checkbox>
                <Checkbox
                  onChange={() => handlePreferencesChange("Laundry services")}
                >
                  Laundry services
                </Checkbox>
                <Checkbox
                  onChange={() => handlePreferencesChange("Valet services")}
                >
                  Valet services
                </Checkbox>
                <Checkbox
                  onChange={() =>
                    handlePreferencesChange("Room Temperature Cold")
                  }
                  disabled={formData.preferences.includes(
                    "Room Temperature Warm"
                  )}
                >
                  Room Temperature Cold
                </Checkbox>

                <Checkbox
                  onChange={() =>
                    handlePreferencesChange("Room Temperature Warm")
                  }
                  disabled={formData.preferences.includes(
                    "Room Temperature Cold"
                  )}
                >
                  Room Temperature Warm
                </Checkbox>
              </div>
            </div>
          </div>
          <div className="other-request">
            <Row gutter={24}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className="form-group">
                  <label className="form-label">Any Special Request</label>

                  <TextArea
                    name="other_request"
                    value={formData.other_request}
                    onChange={handleChange}
                    placeholder="Any Special Request"
                  />
                </div>
              </Col>
            </Row>
          </div>
          <div className="room-temperature">
            <Row gutter={24}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className="form-group">
                  <Checkbox
                    name="extraFries"
                    checked={formData.extraFries}
                    onChange={handleInputChange}
                  >
                    Want Extra Fries with that? <BsEmojiHeartEyesFill />
                  </Checkbox>
                </div>
              </Col>
            </Row>
          </div>
          <div className="payment-information">
            {/* <h3>Payment Information</h3> */}
            <Row gutter={24}>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <div className="form-group">
                  <label className="form-label">Cardholder Name</label>
                  <Input
                    name="creditCardName"
                    value={formData.creditCardName}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <div className="form-group">
                  <label className="form-label">Credit/Debit Card Number</label>
                  <Input
                    name="creditCardNumber"
                    value={formData.creditCardNumber}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <div className="form-group">
                  <label className="form-label">Expiration Month</label>
                  <Input
                    name="expirationMonth"
                    value={formData.expirationMonth}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <div className="form-group">
                  <label className="form-label">Expiration Year</label>
                  <Input
                    name="expirationYear"
                    value={formData.expirationYear}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <div className="form-group">
                  <label className="form-label">CVV Number</label>
                  <Input
                    name="CVVNumber"
                    value={formData.CVVNumber}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <div className="form-group">
                  <label className="form-label">Billing Zip Code</label>
                  <Input
                    name="BillingZipCode"
                    value={formData.BillingZipCode}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
            </Row>
          </div>
          <p>
            All guest rooms and amenities at this hotel offer complementary
            wireless internet access. We encourage you to check in via the
            mobile app or the email link sent prior to your arrival for a smooth
            check-in experience.
          </p>
          <br></br>
          {/* <p> Any maintenance related message.   – admin</p> */}
          <br></br>
          <br></br>
          <div className="form-group">
            <Checkbox
              name="acceptTermCondition"
              checked={formData.acceptTermCondition}
              onChange={handleInputChange}
            >
              I have read the all terms, rate details, and accept the
              cancellation policy.
            </Checkbox>
          </div>

          <Button onClick={handleSubmit} className="btn-book-stay">
            BOOK MY STAY
          </Button>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={9}
          xl={9}
          style={{ marginTop: "20px" }}
        >
          <Cart2 />
        </Col>
      </Row>
    </div>
  );
};

export default ConfirmForm;
