import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Step = ({ active, step2heading }) => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="ptb-4">
      <div className="step-group">
        <div
          className={`step step-1 ${active === 1 ? "active" : ""} ${
            active > 1 ? "complate" : ""
          }`}
        >
          <div className="step-round">
            <img
              className="white-img"
              src={`${process.env.PUBLIC_URL}/stepicon/step1-white.png`}
              alt=""
            />
            <Link to={"/"}>
              <img
                className="complate-img"
                src={`${process.env.PUBLIC_URL}/stepicon/complate.png`}
                alt=""
              />
            </Link>
          </div>
          <h3 className="step-title">SELECT DATES</h3>
        </div>
        <div className="line">
          <div className="center-line">
            {isSmallScreen ? (
              <img
                src={`${process.env.PUBLIC_URL}/images/curve-arrow-black-1.png`}
                alt=""
              />
            ) : (
              <img
                src={`${process.env.PUBLIC_URL}/images/curve-arrow-grey-1.png`}
                alt=""
              />
            )}
          </div>
        </div>
        <div
          className={`step step-2 ${active === 2 ? "active" : ""} ${
            active > 2 ? "complate" : ""
          }`}
        >
          <div className="step-round">
            <img
              className="black-img"
              src={`${process.env.PUBLIC_URL}/stepicon/step2-black.png`}
              alt=""
            />
            <img
              className="white-img"
              src={`${process.env.PUBLIC_URL}/stepicon/step2-white.png`}
              alt=""
            />
            <Link to={"/hotels"}>
              <img
                className="complate-img"
                src={`${process.env.PUBLIC_URL}/stepicon/complate.png`}
                alt=""
              />
            </Link>
          </div>
          <h3 className="step-title">{step2heading ? step2heading : "HOTELS & ROOMS"}</h3>
        </div>
        <div className="line">
          <div className="center-line">
            {isSmallScreen ? (
              <img
                src={`${process.env.PUBLIC_URL}/images/curve-arrow-black-2.png`}
                alt=""
              />
            ) : (
              <img
                src={`${process.env.PUBLIC_URL}/images/curve-arrow-grey-2.png`}
                alt=""
              />
            )}
          </div>
        </div>
        <div
          className={`step step-3 ${active === 3 ? "active" : ""}  ${
            active > 3 ? "complate" : ""
          }`}
        >
          <div className="step-round">
            <img
              className="black-img"
              src={`${process.env.PUBLIC_URL}/images/personalization.png`}
              alt=""
            />
            <img
              className="white-img"
              src={`${process.env.PUBLIC_URL}/stepicon/step3-white.png`}
              alt=""
            />
            <Link to={"/personalization"}>
              <img
                className="complate-img"
                src={`${process.env.PUBLIC_URL}/stepicon/complate.png`}
                alt=""
              />
            </Link>
          </div>
          <h3 className="step-title">Amenities & Rewards</h3>
        </div>
        <div className="line">
          <div className="center-line">
            {isSmallScreen ? (
              <img
                src={`${process.env.PUBLIC_URL}/images/curve-arrow-black-1.png`}
                alt=""
              />
            ) : (
              <img
                src={`${process.env.PUBLIC_URL}/images/curve-arrow-grey-1.png`}
                alt=""
              />
            )}
          </div>
        </div>
        <div
          className={`step step-4 ${active === 4 ? "active" : ""}  ${
            active > 4 ? "complate" : ""
          }`}
        >
          <div className="step-round">
            <img
              className="black-img"
              src={`${process.env.PUBLIC_URL}/images/confirm.png`}
              alt=""
            />
            <img
              className="white-img"
              src={`${process.env.PUBLIC_URL}/stepicon/step4-white.png`}
              alt=""
            />
            <img
              className="complate-img"
              src={`${process.env.PUBLIC_URL}/stepicon/complate.png`}
              alt=""
            />
          </div>
          <h3 className="step-title">CONFIRMATION</h3>
        </div>
      </div>
    </div>
  );
};

export default Step;
