import React from "react";
import ConfirmForm from "../Components/ConfirmForm";
import Step from "../Components/Step";
import CardValidationForm from "../Components/payment/CardValidationForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";


const Confirmation = () => {
// 🔹 Stripe Public Key
  const stripePromise = loadStripe("pk_test_51QpjxXGL8E4XCDtK5y3isiHNNXn2HxPMZEVZi2yq2jSlvidEc4xu9kb5NdzzlegY7Mw9EVKwgX8ZY8qcLGq6FtSt00FikOB5PY");


  return (
    <div style={{ paddingBottom: "50px" }}>
      <div className="container">
        {/* <Step active={4} /> */}

        <div style={{ padding: "50px 0" }}>
          <ConfirmForm />

          <Elements stripe={stripePromise}>
            {/* <CardValidationForm /> */}
          </Elements>
        </div>
      </div>
    </div>
  );
};

export default Confirmation;
